import React, { useEffect, useState } from "react";
import { IntlProvider } from "react-intl";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import BlockDetail from "./components/Pages/Blockchain/BlockDetail";
import VoteDetail from "./components/Pages/Blockchain/VoteDetail";
import VoterDetail from "./components/Pages/Blockchain/VoterDetail";

import Contestant from "./components/Pages/Contestant/Contestant";
import Index from "./components/Pages/Index/Index";
import Introduct from "./components/Pages/Introduct";
// import Rank from "./components/Pages/Rank/Rank";
import RankIndex from "./components/Pages/Rank";
import Notification from "./components/Partials/Notification";
import { connectToSocket, disconnectToSocket } from "./utils/listenSocket";
import AOS from "aos";
import "aos/dist/aos.css";
import { useContestant } from "./store";
import multiLangMes from "./utils/multiLangMes";
import ScrollToTop from "./utils/ScollToTop";

export const ContestantsContext = React.createContext();

export default function App() {
  const [lang, setLang] = useState("vi");
  const { ranks, getRanks } = useContestant();
  // const { ranks, getRanks } = useRank();

  useEffect(() => {
    AOS.init({
      duration: 500,
    });
    // getContestant();
    getRanks();
  }, [getRanks]);
  return (
    <ContestantsContext.Provider value={{ contestants: ranks, ranks }}>
      <IntlProvider locale={lang} messages={multiLangMes[lang]}>
        <Router>
          <Switch>
            <ScrollToTop>
              <div className="App">
                {/* <div className="App" id="main"> */}
                <Route
                  exact
                  path="/"
                  render={(props) => (
                    <Index
                      {...props}
                      setLang={setLang}
                      connectToSocket={connectToSocket}
                      disconnectToSocket={disconnectToSocket}
                      lang={lang}
                    />
                  )}
                />

                <Route
                  path="/gioi-thieu"
                  render={(props) => (
                    // <Intro1 {...props} setLang={setLang} lang={lang} />
                    <Introduct />
                  )}
                />

                <Route
                  path="/bang-xep-hang/:table"
                  render={(props) => (
                    <RankIndex {...props} setLang={setLang} lang={lang} />
                  )}
                />

                <Route
                  path="/thi-sinh/:missId"
                  render={(props) => (
                    <Contestant {...props} setLang={setLang} lang={lang} />
                  )}
                />

                <Route
                  path="/phieu-binh-chon/:txId"
                  render={(props) => (
                    <VoteDetail {...props} setLang={setLang} lang={lang} />
                  )}
                />

                <Route
                  path="/khoi/:current/:next"
                  render={(props) => (
                    <BlockDetail {...props} setLang={setLang} lang={lang} />
                  )}
                />
                <Route
                  path="/nguoi-binh-chon/:voterId"
                  render={(props) => (
                    <VoterDetail {...props} setLang={setLang} lang={lang} />
                  )}
                />
                <Notification
                  connectToSocket={connectToSocket}
                  disconnectToSocket={disconnectToSocket}
                />
              </div>
            </ScrollToTop>
          </Switch>
        </Router>
      </IntlProvider>
    </ContestantsContext.Provider>
  );
}
