import React from "react";
import styled from "styled-components";

const priceList = [
  {
    combo: "50 điểm",
    price: "20.000 đ",
    point: 50,
  },
  {
    combo: "150 điểm",
    price: "50.000 đ",
    point: 150,
  },
  {
    combo: "450 điểm",
    price: "100.000 đ",
    point: 450,
  },

  {
    combo: "1000 điểm",
    price: "200.000 đ",
    point: 1000,
  },

  {
    combo: "2000 điểm",
    price: "300.000 đ",
    point: 2000,
  },
  // {
  //   combo: 200,
  //   price: "1.000.000 đ",
  //   point: 2200,
  // },
];

export default function PriceTable() {
  return (
    <STable className="price-table-warpper">
      {/* <List
        className="table-header"
        items={["Combo vote", "Số tiền", "Tổng điểm vote", "Tổng điểm X2"]}
      >
        {(item, ind) => (
          <div className={"label cell" + (ind == 3 ? " highlight" : "")}>
            {item}
          </div>
        )}
      </List>
      <List className="table-content" items={priceList}>
        {(item) => {
          return (
            <div className="table-item">
              <div className="cell">{item.combo}</div>
              <div className="cell">{item.price}</div>
              <div className="cell">{item.point}</div>
              <div className="cell highlight">{item.point * 2}</div>
            </div>
          );
        }}
      </List> */}
      <table>
        <thead>
          <tr>
            <th>Combo vote</th>
            <th>Số tiền</th>
            <th>
              <span className="bold">Tổng điểm vote</span>
            </th>
            {/* <th className="highlight">Tổng điểm x 2</th> */}
          </tr>
        </thead>
        <tbody>
          {priceList.map((item, index) => {
            return (
              <tr key={index}>
                <td>{item.combo}</td>
                <td>{item.price}</td>
                <td>{item.point}</td>
                {/* <td className="highlight">{item.point * 2}</td> */}
              </tr>
            );
          })}
        </tbody>
      </table>
    </STable>
  );
}

const STable = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 6;
  .cell {
    height: 24px;
    width: 103px;
    border-radius: 4px;
    border: 2px solid #411644;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 600;
    padding-top: 3px;
  }

  .table-header {
    display: flex;
    flex-direction: column;
    .cell {
      background: #d29c44;
      width: 110px;
      color: white;
      &.highlight {
        background: #d48300;
      }
    }
  }

  .table-content {
    display: flex;
  }

  .table-item {
    display: flex;
    flex-direction: column;
    .cell {
      background-color: white;
      color: #4e2550 !important;
      &.highlight {
        color: #d48300 !important;
      }
    }
  }

  table {
    border-collapse: collapse;
    text-align: center;
    tr {
      th,
      td {
        height: 33px;
        width: 144px;
        border: 3px solid #411644;
        border-radius: 10px;
        padding: 7px 5px 4px;
        font-weight: 600;
        color: #411644 !important;
        text-transform: uppercase;
        font-size: 12px;
      }
      td.highlight {
        color: #d48300 !important;
      }
      th {
        color: white !important;
        background: #f738e0;
        &.highlight {
          background: #d48300;
        }
      }
    }
    thead {
      background-color: rgb(69, 30, 75);
      color: #dadb59;
      font-weight: 300;
      th {
        .bold {
          text-transform: uppercase;
        }
      }
    }
    tbody {
      background-color: white;
      color: black;
      tr {
        td {
          .bold {
            text-transform: uppercase;
          }
        }
      }
    }
  }

  table {
    display: none;
  }

  @media only screen and (max-width: 767px) {
    .table-header,
    .table-content {
      display: none;
    }

    display: flex;
    justify-content: center;
    table {
      display: block;
    }
  }
`;
