/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router-dom";
import { useVoteList } from "../../../store";
import LayoutSubPage from "../../Layouts/LayoutSubPage";
import VoteList from "../../Partials/VoteList";
import ContestantDetail from "./ContestantDetail";
import styled from "styled-components";

const Contestant = () => {
  const loader = (
    <div className="text-center ajaxLoading">
      <img
        width="200"
        alt="loading ..."
        src="/images/spinner.gif"
        style={{ display: "inline" }}
      />
    </div>
  );

  const { missId } = useParams();
  // const { contestants } = useContext(ContestantsContext);
  // const info = contestants.find((con) => con.code == missId) || {};
  // console.log("infoooo", info);

  const { voteList, isHasMore, getVoteList } = useVoteList();
  const [filter, setFilter] = useState({ pageNumber: 1, pageSize: 10 });

  useEffect(() => {
    getVoteList(missId, filter.pageNumber, filter.pageSize);
  }, [filter, getVoteList, missId]);
  useEffect(() => {
    const el = document.querySelector("#contestant-detail");
    el && el.scrollIntoView();
  }, []);
  return (
    <Styles>
      <LayoutSubPage>
        {/* <section className=" banner-page">
        <div className="banner-content"></div>
      </section> */}
        {/* <SectionBanner /> */}
        <div className="section-detail">
          <div className="bs-container">
            <div className="bs-row">
              <div className="bs-col">
                <div className="module module-detail">
                  <div
                    className={`module-content`}
                    id="contestant-detail"
                    style={{ position: "relative", zIndex: 1 }}
                  >
                    <ContestantDetail />
                  </div>
                  {/* <div className="module-history">
                  <div className="detail-history">
                    <h3 className="title">
                      <FormattedMessage id="contestant.history" />
                    </h3>

                    <div className="history-table" id="data-container">
                      <InfiniteScroll
                        dataLength={voteList.length}
                        next={() => {
                          setFilter((prev) => ({
                            ...prev,
                            pageNumber: prev.pageNumber + 1,
                          }));
                        }}
                        scrollThreshold={0.9}
                        hasMore={isHasMore}
                        loader={loader}
                        endMessage={
                          <p style={{ textAlign: "center" }}>
                            <b>
                              <FormattedMessage id="none" />
                            </b>
                          </p>
                        }
                      >
                        <table className="table">
                          <thead>
                            <tr>
                              <th>
                                <FormattedMessage id="contestant.index" />
                              </th>
                              <th>
                                <FormattedMessage id="voterId" />
                              </th>
                              <th>
                                <FormattedMessage id="time" />
                              </th>
                            </tr>
                          </thead>

                          <tbody>
                            {voteList.map((el, index) => (
                              <VoteList
                                key={el._id}
                                index={index + 1}
                                display_account={el.voterId}
                                create_time={el.createdAt}
                              />
                            ))}
                          </tbody>
                        </table>
                      </InfiniteScroll>
                    </div>
                  </div>
                </div> */}
                  <div className={`module-content`} id="contestant-detail">
                    <div className="detail-history">
                      <h3 className="title">
                        <FormattedMessage id="contestant.history" />
                      </h3>

                      <div className="history-table" id="data-container">
                        <InfiniteScroll
                          dataLength={voteList.length}
                          next={() => {
                            setFilter((prev) => ({
                              ...prev,
                              pageNumber: prev.pageNumber + 1,
                            }));
                          }}
                          scrollThreshold={0.9}
                          hasMore={isHasMore}
                          loader={loader}
                          endMessage={
                            <p style={{ textAlign: "center" }}>
                              <b>
                                <FormattedMessage id="none" />
                              </b>
                            </p>
                          }
                        >
                          <table className="table">
                            <thead>
                              <tr>
                                <th>
                                  <FormattedMessage id="contestant.index" />
                                </th>
                                <th>
                                  <FormattedMessage id="voterId" />
                                </th>
                                <th>
                                  <FormattedMessage id="time" />
                                </th>
                              </tr>
                            </thead>

                            <tbody>
                              {voteList.map((el, index) => (
                                <VoteList
                                  key={el._id}
                                  index={index + 1}
                                  display_account={el.voterId}
                                  create_time={el.createdAt}
                                />
                              ))}
                            </tbody>
                          </table>
                        </InfiniteScroll>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <SectionBrand /> */}
      </LayoutSubPage>
    </Styles>
  );
};

export default Contestant;

const Styles = styled.section`
  background: white;
  .module-detail {
    .module-content {
      padding: 45px 100px;
      border-top: unset;
      border-radius: 12px;
      box-shadow: 0 0 26px rgba(0, 0, 0, 0.05);
      filter: drop-shadow(4px 4px 24px rgba(166, 98, 207, 0.16));

      margin-bottom: 60px;
      .bs-row {
        .bs-col {
          .detail-left {
            img {
              border-radius: 8px;
            }
          }
          .detail-right {
            .slide-detail {
              padding: 5px 0;
              .text {
                .text-index {
                  border: none;
                  padding: 0;
                  margin-bottom: 25px;
                  .index__name {
                    font-family: beVietnamPro-Bold;
                    font-size: 28px;
                    color: #2b2438;
                  }
                  .item-text {
                    margin-top: 20px;
                    padding: 20px;
                    background: linear-gradient(
                      110.38deg,
                      rgba(247, 56, 224, 0.1) 0%,
                      rgba(66, 198, 255, 0.1) 100%
                    );
                    border-radius: 8px;
                    .index__desc {
                      font-size: 16px;
                      font-family: unset;
                    }
                    .index__desc:nth-child(4) {
                      .title {
                        font-family: beVietnamPro-Bold;
                        font-size: 28px;
                        color: #8854df;
                      }
                    }
                  }
                }
                .item-btn {
                  display: grid;
                  grid-template-columns: 1fr 1fr;
                  align-items: center;
                  justify-content: space-between;
                  gap: 15px;
                  .vote_dropdown {
                    position: relative;
                    border: none;
                    background: linear-gradient(
                      110.38deg,
                      #f738e0 0%,
                      #42c6ff 100%
                    ) !important;
                    z-index: 1;
                    border-radius: 8px;
                    span {
                      background: linear-gradient(
                        110.38deg,
                        #f738e0 0%,
                        #42c6ff 100%
                      );
                      -webkit-background-clip: text;
                      -webkit-text-fill-color: transparent;
                      background-clip: text;
                    }
                    &::after {
                      content: "";
                      display: inline-block;
                      width: calc(100% - 2px);
                      height: calc(100% - 2px);
                      background: #fff !important;
                      border-radius: 8px;
                      position: absolute;
                      top: 50%;
                      left: 50%;
                      transform: translate(-50%, -50%);
                      z-index: -1;
                    }
                  }
                  .btn-vote {
                    background: linear-gradient(
                      110.38deg,
                      #f738e0 0%,
                      #42c6ff 100%
                    ) !important;
                    border-radius: 8px;
                    color: #fff;
                    border: none;
                  }
                }
                .btn-method {
                  .vote_dropdown {
                    border-radius: 8px;
                    .dropdown {
                      background: linear-gradient(
                        110.38deg,
                        #f738e0 0%,
                        #42c6ff 100%
                      ) !important;
                      color: #fff;
                      border-radius: 4px;
                    }
                  }
                  &.active {
                    padding-top: 35px;
                  }
                }
              }
            }
          }
        }
      }
      .detail-history {
        padding-top: 0px;
        .title {
          margin: 0;
          font-size: 52px;
          span {
            &::after {
              display: none;
            }
          }
        }
        .history-table {
          max-width: unset;
          margin-top: 35px;
          box-shadow: unset;
          .table {
            margin-bottom: 0;
            border-collapse: separate;
            border-spacing: 0 6px;
            thead {
              tr {
                background: linear-gradient(
                  110.38deg,
                  #f738e0 0%,
                  #42c6ff 100%
                );
                border-radius: 8px;
                th {
                  background: none;
                  padding: 12px 12px;
                }
                th:first-child {
                  border-top-left-radius: 8px;
                  border-bottom-left-radius: 8px;
                }
                th:last-child {
                  border-top-right-radius: 8px;
                  border-bottom-right-radius: 8px;
                }
              }
            }
            tbody {
              tr:nth-child(odd) {
                background: #f8f5fe;
              }
              tr:nth-child(even) {
                background: #f8f8f8;
              }

              td:first-child {
                border-top-left-radius: 8px;
                border-bottom-left-radius: 8px;
              }
              td:last-child {
                border-top-right-radius: 8px;
                border-bottom-right-radius: 8px;
              }
              tr:hover {
                td:nth-child(1) {
                  border-left: 5px solid #f738e0;
                }
              }
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 1199px) and (min-width: 992px) {
    .module-detail {
      .module-content {
        padding: 45px 30px;
        margin-bottom: 20px;
        .bs-row {
          .bs-col {
            .detail-right {
              .slide-detail {
                .text {
                  .text-index {
                    .index__name {
                      text-align: center;
                      padding-top: 10px;
                    }
                  }
                  .item-btn {
                    .vote_dropdown {
                      padding: 0 10px;
                      span {
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 991px) and (min-width: 768px) {
    .module-detail {
      padding-top: 0px;
      .module-content {
        margin-bottom: 30px;
        .bs-row {
          .bs-col {
            .detail-right {
              .slide-detail {
                .text {
                  .text-index {
                    .index__name {
                      text-align: center;
                      padding-top: 10px;
                    }
                  }
                }
              }
            }
          }
        }
        .detail-history {
          .title {
            span {
              font-size: 43px;
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 767px) and (min-width: 480px) {
    .module-detail {
      padding-top: 0px;
      .module-content {
        padding: 45px 30px;
        margin-bottom: 20px;
        .bs-row {
          .bs-col {
            .detail-right {
              .slide-detail {
                .text {
                  .text-index {
                    .index__name {
                      text-align: center;
                      padding-top: 10px;
                      font-size: 23px;
                    }
                  }
                  .item-btn {
                    button {
                      padding: 0 10px;
                      span {
                        font-size: 15px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .detail-history {
          .title {
            span {
              font-size: 42px;
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 479px) {
    padding-bottom: 0px;
    .module-detail {
      padding-top: 50px;
      .module-content {
        padding: 20px 15px 25px;
        margin-bottom: 20px;
        .bs-row {
          .bs-col {
            .detail-right {
              .slide-detail {
                .text {
                  .text-index {
                    .index__name {
                      text-align: center;
                      padding-top: 10px;
                      font-size: 20px;
                    }
                  }
                  .item-btn {
                    grid-template-columns: 1fr;
                    padding: 0 30px;
                    button {
                      padding: 0 10px;
                      span {
                        font-size: 16px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .detail-history {
          .title {
            span {
              font-size: 32px;
            }
          }
        }
      }
    }
  }
`;
