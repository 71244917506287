export const INTRO_HOME = `
<div class="reward-content">
  <div class="reward-part">
    <p style="text-align:left">
      Được tổ chức bởi Hội Sinh viên trường Đại học Ngoại thương, Duyên dáng Ngoại thương - Beauty & Charm là cuộc thi tìm kiếm và tôn vinh vẻ đẹp toàn diện, về ngoại hình, tri thức, tài năng lẫn tâm hồn của nữ sinh Ngoại thương.
    </p>
    <p style="text-align:left">
      Trên hành trình khẳng định bản thân, nữ sinh Ngoại thương luôn nỗ lực không ngừng để “gieo” hạt mầm xanh tươi cho đời sống, kiến tạo giá trị tích cực, nhân văn cho xã hội. Thấu hiểu và trân quý những điều ấy, năm nay, Beauty & Charm 2023 với chủ đề “𝐀𝐥𝐢𝐠𝐡𝐭 𝐰𝐢𝐭𝐡 𝐀𝐮𝐫𝐚” sẽ hỗ trợ các cô gái trên chặng đường khám phá trọn vẹn bản thân, từ đó, tỏa sáng “vầng hào quang” của riêng mình và lan tỏa giá trị tích cực tới cộng đồng xung quanh.
    </p>
     <p style="text-align:left">
       Đồng hành cùng các Miss sẽ là những gương mặt Manhunt sáng giá, tất cả sẽ cùng chung tay để kiến tạo nên những giá trị nhân ái, lan tỏa sức ảnh hưởng tốt đẹp tới trường Đại học Ngoại thương nói riêng và cộng đồng xã hội nói chung.
    </p>
    <p style="text-align:left">
    Ngoài ra Doanh thu từ bình chọn trên hệ thống Bvote sẽ dành cho hoạt động tình nguyện của sự kiện Hoa khôi Nhân ái trong khuôn khổ Cuộc thi Duyên dáng Ngoại thương - Beauty & Charm 2023.
    </p>
     
    </div>
  </div>
</div>
`;

export const INTRO_TITLE = `Là cổng bình chọn chính thức của cuộc thi DUYÊN DÁNG NGOẠI THƯƠNG - BEAUTY & CHARM 2023, Bvote tự hào mang đến giải pháp bình chọn trực tuyến công bằng, minh bạch cho các thí sinh.`;

export const INTRO = `
  <div class="reward-content">
  <div class="reward-part">
<div class="item">
<p>
<span style="font-size:16px; font-family: roboto; ">
<span style="color:#000000">
<span style="font-family:roboto">
Cổng bình chọn Bvote sẽ đồng hành cùng cuộc thi để tìm kiếm ra những thí sinh được yêu thích nhất qua sự bình chọn của khán giả. 
  </span>
  </span>
  </span>
  </p>
   <p>
  <span style="font-size:16px"><span style="color:#000000">
  <span style="font-family:roboto;line-height: 150%;">
  Việc hoạt động dựa trên công nghệ chuỗi khối của Blockchain giúp giải pháp bình chọn của Bvote sở hữu những ưu điểm vượt trội về tính an toàn, bảo mật, đem lại kết quả công khai và minh bạch nhất. Thay vì sử dụng phương thức truyền thống bằng tin nhắn, khán giả sẽ ủng hộ cho thí sinh mình yêu thích nhất thông qua hệ thống bình chọn trên Website. Giải pháp này cho phép mọi người theo dõi kết quả liên tục, trực tiếp trên hệ thống, một điều mà các hình thức cũ không làm được. Không chỉ loại bỏ nguy cơ gian lận, Bvote còn mang đến trải nghiệm bình chọn mới lạ, tiện lợi và dễ sử dụng. Nhất là khi giải pháp bình chọn của Bvote còn kết hợp cùng các ví điện tử uy tín để đảm bảo mức độ bảo mật cao và giảm thiểu tối đa tình trạng hack vote.
  <br/>
  <br/>
  </span>
    </span>
      </p> 
      <p>
      &nbsp;
      </p> 
      <p>
      <span style="font-size:16px">
      <span style="color:#000000">
      <span style="font-family:roboto">
      <strong class="item-title">
      Cách thức bình chọn:
      </strong>
      <br/>
      </span>
      </span>
      <span style="color:#000000">
      <span style="font-family:roboto"> 
      </span>
      </span>
      </span>
      </p>
      <div class="item-method">
      <div class="item-step">
       <p>
       <span style="font-size:16px">
       <span style="color:#2B2438">
       <span class="item-text" style="font-family:roboto;line-height: 150%;">
       <i>Bước 1: &nbsp;</i> Truy cập website: https://duyendangngoaithuong.bvote.vn/. Tại phần danh sách thí sinh, click chi tiết vào thí sinh muốn bình chọn
       </span>
       </span>
       </span>
       </p> 
       </div>
       <div class="item-step">
       <p>
       <span style="font-size:16px">
       <span style="color:#2B2438">
       <span class="item-text" style="font-family:roboto;line-height: 150%;">
       <i>Bước 2: &nbsp;</i> Chọn cổng thanh toán. Hệ thống sẽ trả về link để bình chọn thí sinh
       </span>
       </span>
       </span>
       </p>
       </div>
       <div class="item-step">
        <p><span style="font-size:16px">
        <span style="color:#2B2438">
        <span class="item-text" style="font-family:roboto;line-height: 150%;">
        <i>Bước 3: &nbsp;</i> Truy cập link thanh toán, tiến hành thanh toán (tương đương bình chọn). 

        </span>
        </span>
        </span>
        </p>
        </div>
        <div class="item-step">
         <p>
         <span style="font-size:16px">
         <span style="color:#2B2438">
         <span class="item-text" style="font-family:roboto;line-height: 150%;">
         <i>Bước 4: &nbsp;</i> Hệ thống check thanh toán thành công, hệ thống Bvote sẽ tiến hành lưu trữ kết quả bình chọn. Phí bình chọn theo gói lựa chọn và 1 lượt bình chọn tương đương với số điểm theo gói bình chọn mà khán giả đã chọn. Lưu ý không giới hạn số lượt bình chọn và số thí sinh bình chọn.
         </span>
         </span>
         </span>
         </p> 
         </div>
         </div>
         <br/>
          <p>
          &nbsp;
          </p> 
          <p>
          <span style="font-size:16px">
          <strong>
          <span>
          <span style="font-family:roboto" class="item-structure">
          Cơ cấu giải thưởng: 
          </span>
          </span>
          </strong>
          </span>
          </p> 
          <p class="structure">
          <span style="font-size:16px">
          <span style="color:#2B2438">
          <span style="font-family:roboto">
          *** Cổng bình chọn của Bvote sẽ chính thức được mở vào 20h00 ngày 30/11/2023 và sẽ kết thúc vào 20h00 ngày 03/12/2023 để chọn ra thí sinh có điểm cao nhất.
          </p> 
          <br/>
          <p class="structure">
          <span style="font-size:16px">
          <span style="color:#2B2438">
          <span style="font-family:roboto">
          *** Thí sinh có số điểm cao nhất bảng Manhunt & Miss sẽ được Đặc cách vào thẳng Đêm Chung Kết Duyên Dáng Ngoại thương 2023, tiến 1 bước gần hơn tới ngôi vị danh giá nhất của cuộc thi.
          </p>
          <br/>
</div>
</div>
</div>
  `;
