import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import LayoutSubPage from "../../Layouts/LayoutSubPage";
import { request } from "../../../store/api/axios";

export class VoterDetail extends Component {
  state = {
    user: "",
    voteList: [],
  };

  async componentDidMount() {
    try {
      const { voterId } = this.props.match.params;

      // const res = await axios.get(`/api/get/voter/${voterId}`);
      const res = await request({
        method: "GET",
        url: `/api/get/voter/${voterId}`,
      });

      const { voteDetail, date, user, blockId, voteList } = res.data;

      this.setState({ voteDetail, date, user, blockId, voteList });
    } catch (error) {
      console.log("xxx Get voter : ", error);
    }
  }

  render() {
    const { voteList, user } = this.state;

    // const title =
    //   this.props.lang === 'en'
    //     ? 'Voter detail - Poinsettia Festival English'
    //     : 'Thông tin người bình chọn - DUYÊN DÁNG NGOẠI THƯƠNG - BEAUTY & CHARM 2023';

    return (
      <LayoutSubPage>
        <>
          <section class=" banner-page">
            <div class="banner-content"></div>
          </section>

          <section className="section-block">
            <div className="bs-container">
              <div className="bs-row">
                <div className="bs-col">
                  <div className="module module-block">
                    <div className="module-content">
                      <div className="vote-content">
                        <p className="title">
                          <span>
                            <FormattedMessage id="voterId" /> -{" "}
                          </span>{" "}
                          {user}
                        </p>
                        <div className="table-content">
                          <table>
                            <thead>
                              <tr>
                                <th>
                                  <FormattedMessage id="voted" />
                                </th>
                                <th>
                                  <FormattedMessage id="time" />
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {voteList.map((el, index) => {
                                return (
                                  <tr key={index}>
                                    <td>{el.contestant_name}</td>
                                    <td>{el.date} </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <SectionBrand /> */}
        </>
      </LayoutSubPage>
    );
  }
}

export default VoterDetail;
